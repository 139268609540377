import {
  CBadge
} from '@coreui/react'
import masks from '@config/masks'

import mask from 'commons/helpers/formatter/formatNumberMask'

import profile from 'assets/images/profile.png'

const userViewAdapter = (user: IUser): IUserView => {
  const { name, email, document, picture }: IUser = user

  const userView = {
    ...user,
    getRequestId: (): string => user?.id,
    getRemoteId: (): string => user?.remoteId || '',
    getWhitelabelId: ():string => user.profiles[0].whitelabel?.id || '',
    getName: (): string => name?.toUpperCase() || email?.split('@')[0].toUpperCase(),
    getEmail: (): string => email,
    getDocument: (): string => mask(document, masks.cpf),
    getPicture: (): string => picture || profile,
    getNameEmail: (): string => `${userView.getName()} <${userView.getEmail()}>`,
    getCreatedAt: (): string => user?.createdAt?.formatted || '-',
    getUpdatedAt: (): string => user?.updatedAt?.formatted || '-',
    getLastLogin: (): string => user?.lastLogin?.formatted || '-',
    getProfile: (): any => (
      (userView.profiles?.length === 0
        && <CBadge color="light">Nenhum perfil definido</CBadge>)
        || (userView.profiles?.length === 1
          && (userView.profiles[0].profile === 'STAFF')
          && (
          <CBadge color="info">Staff</CBadge>
          ))
        || (userView.profiles?.length === 1
          && (userView.profiles[0].profile === 'WHITELABEL_OWNER' || userView.profiles[0].profile === 'ACCOUNT_OWNER')
          && (
          <CBadge color="info">Owner</CBadge>
          ))
        || (userView.profiles?.length === 1
          && (userView.profiles[0].profile === 'COMPANY_MANAGER')
          && (
          <CBadge color="info">Manager</CBadge>
          ))
        || (userView.profiles?.length === 1
          && (userView.profiles[0].profile === 'SUBSIDIARY_MEMBER')
          && (
          <CBadge color="info">Member</CBadge>
          ))
          || (userView.profiles?.length === 1
            && (userView.profiles[0].profile === 'SUBSIDIARY_VIEWER')
            && (
            <CBadge color="info">Viewer</CBadge>
            ))
      || (
      <CBadge color="info">{userView.profiles?.length} perfis definidos</CBadge>
      )
    ),
  }

  return userView
}

export default userViewAdapter
